.App {
  padding-bottom: 100px;
}

.header-title {
  margin-bottom: 50px;
}

.scene {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.font-italic {
  font-style: italic;
}

.header {
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
  color: rgb(235, 87, 47);
}

.header-content {
  pointer-events: none;
}

h1 {
  color: #f22e5c;
  color: rgb(235, 87, 47);
  font-family: "Fredoka One", sans-serif;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  text-transform: uppercase;
  font-size: 1.5rem;
}

body {
  font-family: "Ubuntu", sans-serif;
  font-size: 0.8rem;
  line-height: 1.2rem;
  background-color: #ffcfd3;
  color: #484e58;
}

.text-small {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@media (min-width: 576px) {
  h1 {
    font-size: 2.5rem;
  }
  body {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .text-small {
    font-size: 14px;
    line-height: 21px;
  }
}

a {
  font-weight: bold;
  text-decoration: none;
  color: rgb(0, 60, 255);
}

a:hover {
  text-decoration: none;
  color: rgb(0, 60, 255, 0.5);
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.4);
}
.loading-content {
  font-family: "Fredoka One", sans-serif;
  font-size: 24px;
  background-color: #edf1e5;
  border: 4px solid rgb(235, 87, 47);
  color: rgb(235, 87, 47);
  border-radius: 20px;
}
.img-loading {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #71affb;
}

a.project-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.bg-color-purple {
  background-color: #d1c1ed;
}

.bg-color-green {
  background-color: #d5faac;
}

.bg-color-neutral {
  background-color: #edf1e5;
}

.bg-color-orange {
  background-color: rgb(235, 87, 47);
}

.border-bottom-blue {
  border-bottom: 10px solid #377dff;
}

.border-bottom-orange {
  border-bottom: 10px solid rgb(235, 87, 47);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #edf1e5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "🐽";
  font-size: 30px;
  left: 4px;
  top: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #71affb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.social-button-icon {
  width: 18px;
}

.footer {
  position: absolute;
  bottom: 0;
}
